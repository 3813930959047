.Main_profile_cont {
  height: auto;
  background-color: var(--grey);
}


.ProfileCard_container {
  z-index: 0;
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 400px;
  height: 250px;
  /* border: 3px solid red; */
}

.Doc_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 2rem;
}

.ProfileCard_container_down {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-family: var(--medium);
  border-radius: 20px;
  width: 80%;
  height: 200px;
  margin: auto;

}

.Main_ProfileCard {
  background-color: white;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;

}

.PROFILE_CARD_BUTTON {
  border-radius: 10px;
  font-weight: var(--regular);
  width: 125px;
  margin-top: 1rem;
  padding: 5px;
}

.PROFILE_CARD_DATE {
  font-family: var(--medium);
  font-size: 14px;
  color: var(--dark-grey);
  margin-top: 5%;
}


.PROFILE_CARD_NAME {
  font-family: var(--medium);
  font-size: 25px;
  color: var(--navy);
  font-weight: bold;
  margin-top: 1rem;
  text-align: initial;

}

.PROFILE_CARD_PREVIEW {
  background-color: white;
  color: var(--navy);
  font-family: var(--medium);
  font-size: 15px;
  font-weight: bold;
  border-radius: 5px;
  border: 1px solid var(--navy);
  cursor: pointer;
  margin-top: 1rem;
  padding: 5px;
}



@media screen and (max-width:1520px) {
  .Doc_info {
    width: 80%;
  }

  .ProfileCard_container {
    width: 380px;
  }
}

@media screen and (max-width:1440px) {
  .Doc_info {
    gap: 2%;
  }
}

@media screen and (max-width:1390px) {

  .MARK_LOCATION1 {
    margin-left: 25%;
  }
}

@media screen and (max-width:1320px) {
  .Doc_info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

  }


  .Main_ProfileCard {

    margin: 20px;
  }

  .jobs_info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }


  .view_button,
  .more {
    justify-items: center;
  }

  .jobs_info {
    display: flex;
    flex-wrap: wrap;

  }

  .SavedJobs_container,
  .AppliedJobs_container {
    display: flex;
    flex-direction: column;
  }
}


@media screen and (max-width:1200px) {
  .Main_SavedJobs_Card {
    width: 100%;

  }

  .BOOKMARK_ICON {
    margin-left: 18rem;
  }

  .jobs_info {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .Libanlogo {
    width: 150px;
    height: 150px;
  }

  .MARK_TITLE {
    font-size: 20px;
  }

  .Desc {
    font-size: 12px;
    margin-left: 40%;
    width: 100%;
  }

}

@media screen and (max-width:650px) {
  .Doc_text {
    text-align: left;
  }

}


@media screen and (max-width:510px) {

  .view_button,
  .more {
    justify-items: center;

  }

  .Libanlogo {
    width: 50px;
    height: 50px;
  }
}