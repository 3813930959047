.Main_aboutus {
  height: auto;
  margin-top: 4rem;
}

.heading {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.Aboutus_heading_img {
  position: absolute;
}

.Aboutus_heading_text {
  position: relative;
  color: white;
  top: 120px;
}
.div-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.Aboutus_heading_text_title {
  font-size: 3rem;
  font-family: var(--bold);
  text-align: center;
}

.Aboutus_heading_text_desc {
  font-size: 1.5rem;
  font-family: var(--medium);
  text-align: center;
  margin-top: 2rem;
}

.head_span {
  color: black;
  margin-left: 10px;
}

.Aboutus_main {
  width: 80%;
  margin: auto;
  margin-top: 20rem;
}

.mehnati {
  width: 250px;
  height: 250px;
}

.Aboutus_div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.Aboutus_div2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 6rem;
  margin-left: 2rem;
}

.marginss {
  margin-top: 6rem;
}

.Aboutus_div_img {
  width: 45%;
}

.Aboutus_div_text {
  width: 50%;
  text-align: initial;
}

.Aboutus_div_text_title {
  font-size: 2rem;
  font-family: var(--bold);
  margin-bottom: 1rem;
  color: var(--navy);
}

.Aboutus_div_text_desc {
  font-size: 1rem;
  font-family: var(--regular);
  color: var(--navy);
  margin-bottom: 1rem;
  line-height: 1.5rem;
}

.Aboutus_div_button_btn {
  background-color: white;
  color: var(--navy);
  border: 1px solid var(--navy);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 1rem;
  font-family: var(--bold);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.Aboutus_div_button_btnnot {
  background-color: white;
  color: var(--navy);
  border: 1px solid var(--navy);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 1rem;
  font-family: var(--bold);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  opacity: 0.5;
}

.divider_about {
  width: 80%;
  height: 2px;
  background-color: var(--dark-grey);
  margin: auto;
  margin-top: 10rem;
  margin-bottom: 2rem;
  border-radius: 12px;
}

.Aboutus_footer {
  width: 80%;
  margin: auto;
  margin-top: 6rem;
}

.Aboutus_footer_title {
  font-size: 2.5rem;
  font-family: var(--bold);
  margin-bottom: 2rem;
  color: var(--navy);
}

.Aboutus_footer_sponsors {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  margin-top: 4rem;
  align-items: flex-start;
}
.funded1 {
  width: 22rem;
}
.funded111 {
  width: 30rem;
}
.image-contcont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.image-contcont1 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 2rem;
}

.funded {
  width: 600px;
  height: 417px;
}

.funded2 {
  width: 400px;
  height: 200px;
  margin-left: 2rem;
}
.age {
  width: 10rem;
  margin-left: 2rem;
}
.age1 {
  width: 10rem;
}
.image_cont {
  display: flex;
  flex-direction: column;
}
.image_cont1 {
  display: flex;
  flex-direction: column;
}
.centerthepic {
  justify-content: center;
}
.Aboutus_footer_sponsors11 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.titleaboutus {
  justify-content: flex-start;
  color: var(--navy);
  font-size: 25px;
  font-family: var(--medium);
}

@media screen and (max-width: 750px) {
  .Aboutus_div {
    flex-direction: column;
    align-items: center;
  }

  .Aboutus_div2 {
    flex-direction: column;
    align-items: center;
  }
  .funded {
    width: 50vw;
    height: 30vw;
  }
  .funded111 {
    width: 50vw;
    height: 30vw;
  }
}

@media screen and (max-width: 500px) {
  .funded2 {
    width: 300px;
    height: 250px;
    margin-left: 2rem;
  }

  .funded {
    width: 300px;
    height: 150px;
  }

  .mehnati {
    width: 200px;
    height: 200px;
  }
}
