.Main_company_reg {
    height: auto;
    margin-bottom: 5rem;
}

.Main_company_reg_form {
    margin-top: 20rem;
}

.null_container_3 {
    width: 150px;
    height: 150px;
    background-color: var(--dark-grey);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 10px;

}

.input_complete_textarea {
    width: 75%;
    height: 150px;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 1.1rem;
    margin-bottom: 10px;
    text-align: left;
    font-family: var(--medium);
    cursor: pointer;
    resize: none;
    border-radius: 8px;
}

.message_reg {
    color: red;
    font-size: 1.5rem;
}

.input_complete_textarea_check {
    width: 75%;
    height: 150px;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid var(--green);
    padding: 10px;
    font-size: 1.1rem;
    margin-bottom: 10px;
    text-align: left;
    font-family: var(--medium);
    cursor: pointer;
    resize: none;
    border-radius: 8px;
}