/* .Main_AppliedJobs_Card {
    height: 175px;
  
} */

.AppliedJobsCard_container {
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
  background-color: white;
  width: 80%;
  margin-left: 10%;
  padding: 20px;
}

.MARK_CONT1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 250px;
  /* border:1px solid green; */
  padding-left: 50px;
  height: 123px;
}

.MARK_POSITION1 {
  font-size: 24px;
  color: var(--navy);
  font-weight: bold;
  font-family: var(--medium);
}

.MARK_LOCATION1 {
  color: red;
  font-size: 12px;
}

.MARK_APPLY1 {
  margin-top: 10%;
  color: var(--dark-grey);
}

.MARK_CONT2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0 1 auto;
  margin-left: 5%;
  text-align: initial;
}

.STATUS_TEXT {
  font-size: 24px;
  color: var(--navy);
  font-weight: bold;
  font-family: var(--medium);
  margin-bottom: 1rem;
}

.CALL_BUTTON {
  width: fit-content;
  height: 30px;
  /* background-color: var(--navy); */
  color: white;
  padding: 10px;
  border-radius: 12px;
}

.greenbg {
  background-color: var(--green);
}

.redbg {
  background-color: var(--danger);
}

.purplebg {
  background-color: var(--purple);
}

.cyanbg {
  background-color: var(--cyan);
}

.navybg {
  background-color: var(--navy);
}

.pinkbg {
  background-color: var(--pink);
}

.darkorgbg {
  background-color: var(--dark-orange);
}

.MARK_CONT3 {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  align-items: flex-start;
}

.REASON_TEXT {
  font-size: 24px;
  color: var(--navy);
  font-weight: bold;
  font-family: var(--medium);
  margin-bottom: 1rem;
}

.REASON_INPUT {
  width: 105%;
  height: 55px;
  padding: 5px;
  border: 1px solid var(--dark-grey);
  border-radius: 5px;
  justify-items: center;
}

@media screen and (max-width: 1070px) {
  .AppliedJobsCard_container {
    width: 805px;
  }
}

@media screen and (max-width: 1400px) {
  .AppliedJobsCard_container {
    width: 1060px;
  }
}

@media screen and (max-width: 1350px) {
  .AppliedJobsCard_container {
    width: 1019px;
  }
}

@media screen and (max-width: 1070px) {
  .AppliedJobsCard_container {
    width: 805px;
    padding-bottom: 3%;
  }

  .MARK_CONT3 {
    margin-right: 10%;
  }
}

@media screen and (max-width: 960px) {
  .AppliedJobsCard_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
    margin-left: 0rem;
  }

  .MARK_CONT1 {
    padding-left: 16px;
  }
}
