.Main_edit {
  padding-top: 10rem;
  background-color: var(--dark-grey);
  display: flex;
  text-align: center;
  height: auto;
  justify-content: center;
}

.loader_container {
  height: 100vh;
  margin-top: 10rem;
}

.edit_container {
  background-color: white;
  width: 80%;
  margin: auto;
  margin-top: 12rem;
  height: 90%;
  padding-top: 5%;
  padding-left: 5%;
  margin-bottom: 20%;
}

.info_container {
  display: flex;
  justify-content: space-around;
  font-family: var(--dark-grey);
  margin-top: 1rem;
  /* flex-direction: column; */
}

.text_container1 {
  display: flex;
  flex-direction: row;
  width: 150px;
  margin-top: 5px;
  text-align: left;
}

.text_container2 {
  display: flex;
  flex-direction: row;
  width: 80px;
  margin-top: 50px;
  text-align: left;
}

.upload_files {
  display: flex;
  flex-direction: row;
}

.upload_button {
  width: 110px;
  height: 100px;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid var(--dark-grey);
}

.upload_button2 {
  width: 110px;
  height: 100px;
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid var(--dark-grey);
  background-color: var(--dark-grey);
}

.input_file_editprofile {
  border: none;
  background-color: inherit;
  font-size: 1.1rem;
  color: var(--dark-grey);
}

.update {
  border-radius: 50px;
  color: white;
  background-color: var(--green);
  font-family: var(bold);
  align-items: center;
  padding: 20px;
  border: none;
  margin-top: 10%;
  margin-bottom: 10%;
  margin-right: 5%;
  width: 20%;
  cursor: pointer;
}

.border {
  border: 1px solid var(--dark-grey);
  margin: auto;
  width: 300px;
  padding: 5px;
  margin-left: 10%;
}

.input_complete {
  width: 40%;
  padding: 10px;
  border: 1px solid var(--dark-grey);
  border-radius: 8px;
}

.Edit_text {
  font-weight: bold;

  width: 30%;
  justify-content: start;
}

@media screen and (max-width: 680px) {
  .input_complete {
    width: 200px;
    height: 50px;
  }

  .border {
    width: 100px;
    height: 100px;
  }

  .input_file {
    width: 90px;
  }
}

@media screen and (max-width: 390px) {
  .info_container {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 534px) {
  .Main_edit {
    width: 200%;
  }
}
.styleDrop {
  width: 42%;
}
