.Main_company {
  background-color: #f5f5f5;
  height: 170vh;
  height: auto;
}

.filter_bar {
  margin-top: 4rem;
  width: 100%;
  background-color: var(--grey);
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.upperfilters_box {
  margin-top: 2rem;
}

.Main_company .cont_search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 90%;
  margin: auto;
  border-radius: 8px;
  background-color: white;
}

.input_complete_search {
  width: 80%;
  padding: 20px;
  /* border: 1px solid var(--dark-grey); */
  border: none;
  border-radius: 8px;
}
.joblisting_body {
  margin: auto;
  margin-top: 20rem;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Aboutus_heading_text_title_3 {
  font-size: 3rem;
  font-family: var(--bold);
  text-align: center;
  margin-top: 4rem;
}
.carousel-item {
  margin-left: 5rem;
}
.container-com {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  flex-wrap: wrap;
}

.filter_icons_container {
  background-color: white;
  width: 90%;
}
.icons_drop {
  display: flex;
  /* margin: auto; */
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  align-items: center;
}
.icons_dropdown {
  position: relative;
}
.Main_company .title_drop_cont {
  width: fit-content;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 20px;
}
.title_drop {
  font-size: 1.1rem;
  font-family: var(--bold);
}
.arrow_icon_rot {
  transform: rotate(90deg);
  transition: all 0.5s;
}
.arrow_icon {
  transform: rotate(270deg);
}

.Header_nav_dropdown_job {
  z-index: 3;
  top: 60px;
  position: absolute;
  background-color: white;
  box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.5) !important;
  width: fit-content;
  white-space: nowrap;
  padding: 10px 10px;
  left: -80px;
  border-radius: 5px;
  height: 208px;
  overflow-y: auto;
}

.title_drop_cont {
  width: fit-content;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 20px;
}
.trash_cont {
  background-color: var(--grey);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.companies_cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: auto;
  margin-top: 5rem;
}
.activities_name {
  font-size: 3rem;
  font-family: var(--bold);
  text-align: left;
}
