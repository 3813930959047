.Main_job_listss {
  background-color: var(--grey);
}

.Main_job_listss_inner {
  margin: auto;
  margin-top: 25rem;
  width: 80%;
}

.Main_job_listss {
  height: auto;
  padding-bottom: 5rem;
}
.nosavedjob {
  margin-top: 10rem;
  text-align: center;
  font-size: 2rem;
  color: var(--dark-grey);
  text-transform: uppercase;
}
