.companies_card {
    width: 300px;
    height: 420px;
    background: #ffff 0% 0% no-repeat padding-box;
    border: 3px solid #CCCCCC;
    border-radius: 8px;
    opacity: 1;
    box-shadow: 4px 3px 6px #00000029;
    max-height: 376px;
    padding: 10px;
}

.companies_card_img {
    width: 100%;
    height: 220px;
    object-fit: contain;


}

.companies_card_img_cont {
    width: 100%;
    height: 220px;
    border-bottom: 3px solid #CCCCCC;

}

.companies_card_text_cont {
    width: 80%;
    display: flex;
    margin: auto;
    margin-top: 1rem;
    flex-direction: column;

}

.companies_card_status {
    font-family: var(--bold);
    border-radius: 8px;
    padding: 0 5px;
    align-items: center;
    display: flex;
    height: 25px;
    width: 130px;
    white-space: nowrap;
    justify-content: center;
}

.pinkcolor {
    color: var(--pink);
    border: solid 2px var(--pink);
}

.greencolor {
    color: var(--green);
    border: solid 2px var(--green);
}

.purplecolor {
    color: var(--purple);
    border: solid 2px var(--purple);
}

.companies_card_category {
    font-family: var(--regular);
    display: flex;
    margin-top: 5px;
    font-size: 14px;
    color:red
}

.companies_card_name {
    font-family: var(--bold);
    font-size: 1rem;
    margin-top: 5px;
    color: var(--navy);
    display: flex;
}

.companies_card_text {
    display: flex;
    text-align: initial;
    margin-top: 25px;
    color:#CCCCCC
}

.companies_card_btn {
    display: flex;
    margin-top: 5px;
    margin-bottom: 1rem;
    font-size: 1rem;
    color: var(--navy);
    cursor: pointer;
    font-family: var(--bold);
}