.Main_job_details {
  height: auto;
  background-color: var(--grey);
  margin-bottom: 5rem;
}

.FirstPart_Container {
  width: 80%;
  padding-top: 24rem;
  margin: auto;
  text-align: initial;
}

.Job_Title {
  color: var(--navy);
  font-family: var(--medium);
  font-weight: bold;
  font-size: 34px;
  margin-top: 1rem;
}

.small_cont {
  display: flex;
  flex-direction: row;
  /* border:1px solid red; */
  justify-content: space-between;
}

.Apply_button {
  color: white;
  background-color: var(--navy);
  border-radius: 5px;
  width: 20%;
  font-family: var(--medium);
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.Apply_button_2 {
  color: var(--pink);
  background-color: var(--grey);
  border-radius: 5px;
  width: 20%;
  font-family: var(--medium);
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.Apply_button:hover {
  background-color: white;
  color: var(--navy);
}

.Job_Title_info {
  color: var(--navy);
  font-family: var(--medium);
  font-size: 20px;
}

.icon {
  width: 67px;
  height: 65px;
  margin-top: 3rem;
  margin-right: 1rem;
}

.Icons_Container {
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
  margin: auto;
  gap: 30px;
  margin-top: 1rem;
  align-items: start;
}

.label {
  margin-top: 1rem;
  color: #888888;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.Images_Container {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-left: 14rem;
}

.box_container1 {
  background-image: url("./../../assets/images/profile/Path\ 785.png");

  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 80%;
  margin-top: 3rem;
  height: 250px;
  object-fit: cover;
  background-repeat: no-repeat;
}

.box_container2 {
  background-image: url("./../../assets/images/profile/Path\ 786.png");

  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 80%;
  object-fit: cover;

  margin-top: 3rem;
  height: 250px;
  background-repeat: no-repeat;
}

.box_container3 {
  background-image: url("./../../assets/images/profile/Path\ 787.png");
  object-fit: cover;

  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 80%;
  margin-top: 3rem;
  height: 250px;
  background-repeat: no-repeat;
}

.box_container4 {
  background-image: url("./../../assets/images/profile/Path\ 789.png");
  object-fit: cover;

  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 80%;
  margin-top: 3rem;
  height: 250px;
  background-repeat: no-repeat;
}

.box_container5 {
  object-fit: cover;
  background-image: url("./../../assets/images/profile/Path\ 789.png");

  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 80%;
  margin-top: 3rem;
  height: 250px;
  background-repeat: no-repeat;
}

.IMAGE_TEXT {
  color: white;
  font-family: var(--medium);
  font-weight: bold;
  font-size: 24px;
  margin: auto;
}

.image {
  margin: auto;
  width: 200px;
  height: 200px;
  justify-content: space-between;
}

.containersecond {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10rem;
  width: 90%;
  margin: auto;
  /* margin-left: 10rem;
    margin-bottom: 3rem; */
  justify-content: center;
}

.SecondPart_Container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  gap: 5rem;
  flex-wrap: wrap;
}

.require_cont {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
}

.desc_cont {
  width: 80%;
  margin: auto;
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
}

.message_app {
  font-size: 3rem;
  font-family: var(--bold);
  color: red;
}

.Title {
  color: var(--navy);
  font-family: var(--bold);
  font-size: 2rem;
  text-align: left;
  margin-left: 4rem;
  width: 80%;
  margin-top: 3rem;
}
.category-conttt {
  position: absolute;
}
.home_stats_item_text1 {
  position: relative;
  z-index: 1;
  width: 230px;
  top: 6rem;
  right: 1rem;
  color: white;
}
.home_stats_item_text1212 {
  position: relative;
  z-index: 1;
  width: 230px;
  top: 8rem;
  right: 1rem;
  color: white;
}
.home_stats_item_number11{
  font-size: 15px;
}

.qual_div,
.benefits_cont,
.rel_list {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.require_cont {
  display: flex;
  flex-direction: column;
}

.view_button1:hover {
  background-color: var(--navy);
  color: white;
}

.Qual_text_desc,
.Job_requ_Text,
.Job_Desc_Text,
.Job_benefits_text {
  font-family: var(--medium);
  font-size: 20px;
  text-align: left;
  color: #888888;
  margin-left: 4rem;
  margin-top: 2rem;
}

.list_two,
.list_one,
.list_three {
  list-style-type: none;
}

.home_stats_icon1 {
  width: 226px !important;
  height: 216px !important;
  z-index: 0;
}
.home_stats_icon1111 {
  width: 226px !important;
  height: 216px !important;
  z-index: 0;
  margin-left: 8rem;
  margin-top: 3rem;
}

@media screen and (max-width: 1455px) {
  .containersecond {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 6rem;
    width: 101%;
    margin: auto;
    margin-bottom: 7rem;
  }
}

@media screen and (max-width: 1244px) {
  .box_container1,
  .box_container2,
  .box_container3,
  .box_container4,
  .box_container5 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    height: 223px;
    width: 240px;
  }
}

@media screen and (max-width: 1240px) {
  .box_container1,
  .box_container2,
  .box_container3,
  .box_container4,
  .box_container5 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    height: 223px;
    width: 300px;
  }
}

@media screen and (max-width: 1221px) {
  .box_container1,
  .box_container2,
  .box_container3,
  .box_container4,
  .box_container5 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    height: 223px;
    width: 270px;
  }
}

@media screen and (max-width: 760px) {
  .FirstPart_Container{
    text-align: center;
  }

  .small_cont {
    display: block;
  }

  .Icons_Container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .Images_Container {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-around; */
  }

  .Main_job_details {
    width: 85%;
  }
}

@media screen and (max-width: 550px) {
  .containersecond {
    align-items: center;
    justify-content: space-around;
    gap: 10rem;
    margin-bottom: 5rem;
  }

  .Qual_text_desc,
  .Job_requ_Text,
  .Job_Desc_Text,
  .Job_benefits_text {
    align-items: start;
    margin-right: 1rem;
  }

  .Main_job_details {
    width: 85%;
  }
}

@media screen and (max-width: 1150px) {
  .Qual_text_desc,
  .Job_requ_Text,
  .Job_Desc_Text,
  .Job_benefits_text {
    font-size: 20px;
    width: auto;
    margin-left: 2rem;
  }

  .Main_job_details {
    width: 150%;
  }
}

@media screen and (max-width: 1280px) {
  .Main_job_details {
    height: 780vh;
    width: 100%;
  }
}

@media screen and (max-width: 485px) {
  .Main_job_details {
    height: 800vh;
    width: 100%;
  }
}

@media screen and (max-width: 443px) {
  .Main_job_details {
    height: 850vh;
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .Main_job_details {
    height: 860vh;
    width: 150%;
  }
}

@media screen and (max-width: 377px) {
  .Main_job_details {
    height: 880vh;
    width: 150%;
  }
}

@media screen and (max-width: 365px) {
  .Main_job_details {
    height: 900vh;
    width: 150%;
  }
}

@media screen and (max-width: 376px) {
  .Main_job_details {
    height: 920vh;
    width: 150%;
  }
}

@media screen and (max-width: 344px) {
  .Main_job_details {
    height: 940vh;
    width: 150%;
  }
}

@media screen and (max-width: 293px) {
  .Main_job_details {
    height: 960vh;
    width: 85%;
  }
}

@media screen and (max-width: 307px) {
  .Main_job_details {
    height: 980vh;
    width: 150%;
  }
}

@media screen and (max-width: 280px) {
  .Main_job_details {
    height: 1000vh;
    width: 150%;
  }
}
