.Main_home_div {
  height: auto;
  background-color: #f8f9fb;
  width: 100%;
  margin: auto;
  padding-top: 8rem;
}

.home_career {
  width: 80%;
  margin: auto;
  /* margin-top: 6rem; */
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
}

.home_career_title {
  font-family: var(--bold);
  font-size: 3rem;
  color: var(--navy);
  cursor: pointer;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 400px;
  text-align: initial;
}

.home_career_img_container {
  width: 60%;
}

.home_career_text_cont {
  width: 35%;
}

.home_career_img {
  width: 85%;
  height: 600px;
  animation: spin 2s linear infinite;
}

.home_career_img {
  animation: moveUpDown 1s linear infinite alternate;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(2%);
  }
}

.home_stats_item_icon {
  width: 230px;
  position: absolute;
}

.home_stats_icon {
  width: 230px;
  height: 230px;
  z-index: 0;
}

.home_stats_item_text {
  position: relative;
  z-index: 1;
  width: 230px;
  top: 40px;
  right: 10px;
  color: white;
}

.home_stats_item_number {
  font-size: 16px;
  font-family: var(--bold);
  margin-left: 0.5rem;
  margin-top: 5rem;
}
.home_stats_item_number11 {
  font-size: 16px;
  font-family: var(--bold);
  margin-left: 1rem;
  margin-top: 3rem;
}
.white-link {
  color: white !important;
}

.home_stats_item_number111 {
  font-size: 16px;
  font-family: var(--bold);
  margin-left: 12rem;
  width: 7rem;
}
.home_stats_item_number2 {
  font-size: 20px;
  font-family: var(--bold);
  margin-left: 5px;
}

.home_stats_miniicons {
  width: 60px;
  height: 60px;
}

.home_stats_item_title {
  font-size: 17px;
  font-family: var(--regular);
  width: 130px;
  display: flex;
  margin: auto;
  flex-wrap: wrap;
  margin: auto;
  align-items: center;
  margin-left: 5rem !important;
}

.home_stats_item_title2 {
  font-size: 17px;
  font-family: var(--regular);
  width: 122px;
  display: flex;
  margin: auto;
  flex-wrap: wrap;
  margin: auto;
  align-items: center;
  margin-left: 5rem !important;
}

.home_stats_item_title1 {
  font-size: 17px;
  font-family: var(--regular);
  width: 85px;
  display: flex;
  margin: auto;
  margin-left: 5rem !important;
}

.home_stats {
  display: flex;
  margin: auto;
  width: 100% !important;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  height: auto;
  gap: 1rem;
}
.home_stats1 {
  display: flex;
  margin: auto;
  width: 100% !important;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  height: auto;
  gap: 9rem;
  margin-bottom: 3rem;
}
@media screen and (max-width: 1322px) {
  .home_stats {
    gap: 6rem;
    width: 100%;
  }
}

.home_section2_cont,
.home_section3_cont {
  width: 80%;
  margin: auto;
  margin-top: 9rem;
}
.nomatch {
  color: #171546;
  font-family: var(--bold);
  font-size: 1rem;
}

.home_section2 {
  margin-top: 4rem;
}

.home_section2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 4rem;
  gap: 2rem; 
  justify-content: center;
}

.icon_logo {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.home_section3_button {
  margin-top: 2rem;
  background: var(--navy) 0% 0% no-repeat padding-box;
  border: 1px solid #f7f7f7;
  background: #171546 0% 0% no-repeat padding-box;
  border: 1px solid #f7f7f7;
  border-radius: 6px;
  opacity: 1;
  color: white;
  padding: 20px;
  cursor: pointer;
}

.home_section3_button:hover {
  color: var(--navy);
  border: 2px solid var(--navy);
  background-color: white;
  font-weight: bold;
  font-size: 1.2rem;
  font-family: var(--normal);
  box-shadow: var(--navy) 0px 0px 10px 1px;
}
.home_section3_button_text {
  font-size: 1.2rem;
  font-family: var(--normal);
}

.home_section4_cont {
  margin: auto;
  margin-top: 6rem;
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4rem;
  justify-content: center;
}

.home_section4_1 {
  width: 45%;
  background: #a2bf83 0% 0% no-repeat padding-box;
  border-radius: 12px;
  height: 450px;
}

.home_section4_2 {
  width: 45%;
  background: var(--yellow) 0% 0% no-repeat padding-box;
  border-radius: 12px;
  height: 450px;
}

.home_career_text_cont_4 {
  width: 70%;
  display: flex;
  margin: auto;
  flex-direction: column;
  text-align: initial;
  align-items: center;
  padding-top: 2rem;
}

.home_career_title_sec4 {
  margin-top: 4rem;
  color: var(--navy);
  font-family: var(--bold);
  font-size: 20px;
}

.home_career_subtitle_sec4 {
  margin-top: 1rem;
  color: var(--navy);
  font-family: var(--bold);
  font-size: 2.5rem;
  width: 250px;
}



.home_career_title2_sec4 {
  margin-top: 1rem;
  color: var(--navy);
  font-family: var(--bold);
  font-size: 20px;
}

.home_section4_button {
  margin-top: 1rem;
  color: white !important;
}

.home_section4_button_text {
  background-color: var(--navy);
  color: white;
  border-radius: 5px;
  width: 150px;
  padding: 5px;
  text-align: center;
  font-family: var(--bold);
  cursor: pointer;
}
.home_section4_button_text:hover {
  background-color: white;
  color: var(--navy);
  border: 2px solid var(--navy);
  font-weight: bold;
  box-shadow: var(--navy) 0px 0px 10px 1px;
}
.home_section5_cont {
  background-color: var(--navy);
  height: auto;
  width: 100%;
  margin-top: 6rem;
}

.home_section5_cont_all {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  width: 80%;
  margin: auto;
  padding: 20px;
}

.home_section5_text_container {
  width: 55%;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  margin: auto;
  height: fit-content;
  gap: 2rem;
}


.home_section5_img_container {
  width: 40%;
  display: flex;
  align-items: center;
}

@media screen and (max-width:1000px){
  .home_section5_img_container{
    margin: auto;
  }
  .home_section5_cont_all{
    flex-direction: column;
  }
}

.carousel-item1 {
  z-index: 0;
}
.home_section5_img {
  width: 100%;
  height: auto;
}

.home_section5_title {
  font-family: var(--bold);
  font-size: 2.5rem;
  width: 85%;
  text-align: justify !important;
  margin: auto;
}

.home_section5_button {
  width: 140px;
  height: 40px;
  background-color: white;
  color: var(--navy);
  font-family: var(--bold);
  border-radius: 12px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin: auto;
}

.home_section5_button_cont {
  width: 74%;
}

.home_section5_subtitle {
  font-family: var(--bold);
  font-size: 20px;

  width: 450px;
  text-align: initial;
  margin: auto;
}

.home_section5_button:hover {
  width: 140px;
  height: 40px;
  background-color: var(--navy);
  box-shadow: white 0px 0px 10px 1px;
  color: white;
  font-family: var(--bold);
  border-radius: 12px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin: auto;
}

.home_section6_cont {
  width: 66% !important;
  margin: auto;
  margin-top: 4rem;
}

.home_cat_title {
  font-family: var(--bold);
  font-size: 2.5rem;
  color: var(--navy);
  cursor: pointer;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 44vw;
  text-align: initial;
}

.catergories_container {
  height: auto;
  width: 100%;
}

.cat_all {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;

  margin-bottom: 4rem;
  justify-content: center;
}

.cat_button {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #0000004d;
  border: 2px solid #171546;
  border-radius: 6px;
  opacity: 1;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: var(--bold);
  font-size: 1.2rem;
  color: var(--navy);
}
.cat_button:hover {
  color: white;
  background-color: var(--navy);
  box-shadow: var(--navy) 0px 0px 10px 1px;
}
.home_section7_cont {
  width: 80%;
  margin: auto;
  margin-top: 6rem;
  padding-bottom: 3rem;
}

.home_section7_cont_title {
  font-family: var(--bold);
  font-size: 3rem;
  color: var(--navy);
  cursor: pointer;
  margin-top: 2rem;
  width: 100%;
}

.home_section7_cont_all {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin: auto;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.companies_img {
  width: 120px;
  height: 120px;
}

.companies_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.companies_container {
  background-color: white;
  width: fit-content;
}

@media screen and (max-width: 1030px) {
  .home_career {
    flex-direction: column;
  }

  .home_stats {
    justify-content: center;
  }
}

@media screen and (max-width: 790px) {
  .home_career_img {
    width: 85%;
    object-fit: contain;
  }

  .home_career_title {
    width: 300px;
  }

  .home_section5_title {
    width: 85%;
  }

  .home_section5_subtitle {
    width: 300px;
  }

  .home_career_text_cont {
    width: 100%;
  }

  .home_career_img_container {
    width: 100%;
  }

  .home_section4_1 {
    width: 100%;
  }

  .home_section4_2 {
    width: 100%;
  }

  .home_section2 {
    gap: 7rem;
  }
}

@media screen and (max-width: 410px) {
  .stories_card {
    width: 100%;
  }

  .home_section5_title {
    width: 85%;
  }

  .home_section5_subtitle {
    width: 100%;
  }

  .home_career_img {
    width: 85%;
  }
}
