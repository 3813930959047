.Main_stories_divs {
  background: #ffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 8px;
  opacity: 1;
  box-shadow: 4px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
}
.Main_stories_divs:hover {
  box-shadow: -1px 5px 29px 11px rgba(0, 0, 0, 0.17);
  -webkit-box-shadow: -1px 5px 29px 11px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: -1px 5px 29px 11px rgba(0, 0, 0, 0.17);
}
.stories_cards {
  width: 380px;
  height: 455px;
  background: #ffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 8px;
  opacity: 1;
  box-shadow: 4px 3px 6px #00000029;
}
.stories_card_img {
  width: 300px;
  height: 220px;
  object-fit: cover;
}

.stories_card_img_cont {
  width: 100%;
  height: 220px;
  border-bottom: 3px solid #cccccc;
}

.stories_card_text_cont {
  width: 80%;
  display: flex;
  margin: auto;
  margin-top: 1rem;
  flex-direction: column;
}

.stories_card_status {
  font-family: var(--bold);
  border-radius: 8px;
  padding: 0 5px;
  align-items: center;
  display: flex;
  height: 25px;
  width: 130px;
  white-space: nowrap;
  justify-content: center;
}

.pinkcolor {
  color: var(--pink);
  border: solid 2px var(--pink);
}

.greencolor {
  color: var(--green);
  border: solid 2px var(--green);
}

.purplecolor {
  color: var(--purple);
  border: solid 2px var(--purple);
}

.stories_card_date {
  font-family: var(--regular);
  display: flex;
  margin-top: 5px;
  font-size: 14px;
}

.stories_card_name {
  font-family: var(--bold);
  font-size: 1.5rem;
  margin-top: 5px;
  color: var(--navy);
  display: flex;
  text-wrap: nowrap;
}

.stories_card_text {
  display: flex;
  text-align: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  width: 100%;
  word-wrap: break-word; 
  overflow-y: hidden;
}

.stories_card_btn {
  display: flex;
  margin-top: 5px;
  margin-bottom: 1rem;
  font-size: 1rem;
  color: var(--navy);

  font-family: var(--bold);
}
