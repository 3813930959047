.Main_complete_prof {
  height: auto;
}

.CompleteProfileStep1_container {
  margin-top: 20rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
}

.error_user {
  color: red;
  font-size: 1rem !important;
}

.mehnatilog {
  width: 300px;
  height: 300px;
  object-fit: cover;
}
.disabled {
  opacity: 0.5; /* Add any styles to indicate the div is disabled */
  pointer-events: none; /* Disable pointer events on the div and its contents */
}

.CompleteProfileStep1_container_left {
  background-color: var(--purple);
  width: 50%;
  height: 500px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
}

.CompleteProfileStep1_container_right_heading_title {
  font-size: 2rem;
  color: var(--navy);
  font-family: var(--bold);
  margin-bottom: 1rem;
}

.CompleteProfileStep1_container_right {
  background: #f8f9fb 0% 0% no-repeat padding-box;
  width: 45%;
  height: 500px;
}
.input_complete_1 {
  width: 69%;
  height: 38px;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #ccc;
  padding: 5px;
  margin-bottom: 10px;
  text-align: left;
  font-family: var(--medium);
  cursor: pointer;
}
.input_complete_12 {
  width: 75%;
  height: 38px;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #ccc;
  padding: 5px;
  margin-bottom: 10px;
  text-align: left;
  font-family: var(--medium);
  cursor: pointer;
  font-size: 1.5rem;
}

.input_complete_1_check {
  width: 75%;
  height: 40px;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid var(--green);
  padding: 5px;
  margin-bottom: 10px;
  text-align: left;
  font-family: var(--medium);
  cursor: pointer;
}
.dateofbirth {
  font-size: 0.7rem;
  display: flex;
  margin-left: 2rem;
  font-style: italic;
}
.dateofbirth1 {
  font-size: 12px;
  display: flex;
  margin-left: 15%;
  font-style: italic;
  text-align: initial;
}
.input_complete_2 {
  width: 77%;
  height: 50px;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #ccc;
  padding: 5px;
  margin-bottom: 10px;
  text-align: left;
  font-family: var(--medium);
  cursor: pointer;
}

.input_complete_2_checked {
  width: 77%;
  height: 50px;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid var(--green);
  padding: 5px;
  margin-bottom: 10px;
  text-align: left;
  font-family: var(--medium);
  cursor: pointer;
}

.inputs_upload {
  display: flex;
  gap: 10px;
}

.inputs_upload2 {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.allabove {
  font-size: 14px;
  color: var(--dark-grey);
  text-align: initial;
  width: 200px;
  margin: auto;
}

.hidden {
  display: none;
}

.nextbutton {
  background-color: var(--navy);
  color: white;
  width: 350px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 5px;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
}
/* Add this CSS to your stylesheet */

.nextbutton1 {
  background-color: var(--navy);
  color: white;
  width: 350px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 5px;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
  opacity: 0.5;
}

.nextbuttondisable {
  background-color: var(--navy);
  color: white;
  opacity: 0.5;
  width: 350px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 5px;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
}

.button_container {
  display: flex;
  justify-content: center;
}

.Form_container {
  display: flex;
  flex-direction: column;
}

.null_container {
  width: 40px;
  height: 48px;
  border: 1px solid var(--dark-grey);
  display: flex;
  justify-content: center;
  align-items: center;
}

.complete_data_cont {
  display: flex;
  flex-direction: row;
  margin-left: 2rem;
  width: 90%;
}

.complete_data_cont_1 {
  display: flex;
  flex-direction: row;
  margin-left: 2rem;
  gap: 100px;
}

.profile_data_cont_2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.profile_data_cont_2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.certificate {
  width: 28vw;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 5px;
  margin-bottom: 10px;
  text-align: left;
  font-family: var(--medium);
}

.checked_border {
  border: 1px solid var(--green);
}

.attach {
  font-size: 14px;
  color: var(--dark-grey);
  text-align: initial;
  width: 200px;
  margin: auto;
  margin-left: 10px;
}
.tech {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10vw;
}
.backback {
  margin-left: 1rem;
}
.delete {
  margin-top: 0.5rem;
}
.message_cont_profile {
  margin-top: 10rem;
}

.cv_attachment {
  display: flex;
  width: 80%;
  margin: auto;
  flex-direction: row;
}

.profile_img_prev {
  width: 180px;
  height: 180px;
  object-fit: cover;
}

.div_add {
  width: 40px;
  height: 40px;
  border: 2px solid var(--navy);
  border-radius: 50%;
  cursor: pointer;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: end;
}

.div_add2 {
  width: 40px;
  height: 40px;
  background-color: var(--navy);
  border-radius: 50%;
  cursor: pointer;
  font-size: 30px;
  display: flex;
  color: white;
  justify-content: center;
  align-items: end;
}

.agree_cont {
  display: flex;
  align-items: center;
  margin: auto;
}
.useprof {
  color: black;
  font-size: 0.9rem;
  font-style: italic;
}
.agree {
  font-size: 14px;
  color: var(--dark-grey);
}

.profile_img_cont_2 {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}

.profile_img {
  border-radius: 8px;
  padding: 5px;
  border: 1px solid var(--dark-grey);
}

.null_container_2 {
  width: 150px;
  height: 150px;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

.text_cont_profile_pic {
  font-size: 1.2rem;
}

.subtext_cont_profile_pic {
  font-size: 1rem;
  color: var(--dark-grey);
}

.signup_data {
  margin: auto;
  width: 70%;
  display: flex;
  flex-direction: row;
}

.react-tel-input .form-control {
  border: none !important;
  background: inherit !important;
}

.react-tel-input .flag-dropdown {
  background-color: inherit !important;
  border: none !important;
}

.react-tel-input {
  width: 75% !important;
}

@media screen and (max-width: 610px) {
  .CompleteProfileStep1_container {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .CompleteProfileStep1_container_left {
    width: 100%;
  }

  .CompleteProfileStep1_container_right {
    width: 100%;
  }

  .react-tel-input {
    width: 80% !important;
  }
}
