.Main_contact {
  background-color: #f5f5f5;
  height: 100%;
}

.Contactus_main_left {
  width: 45%;
  margin: auto;
}

.Contactus_main_right {
  width: 40%;
}
.message-div {
  width: 20rem;
  margin-top: 2rem;
  text-align: center;
  color: red;
  margin-left: 5rem;
}
.Contactus_main_title {
  font-size: 2.5rem;
  font-family: var(--bold);
  margin-bottom: 1rem;
  color: var(--navy);
  text-align: initial;
}

.Contactus_main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  width: 80%;
  margin: auto;
  margin-top: 20rem;
}
@media screen and (max-width:450px){
  .Contactus_main {
    flex-direction: column;
  }
}

.Contactus_main_form_input {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 3rem;
  width: 80%;
}

.Contactus_main_form_input_name {
  border: 1px solid var(--dark-grey);
  padding: 10px;
  background-color: transparent;
}

.PhoneInputInput {
  border: none;

  background-color: transparent;
}

.Contactus_main_form_input_subject {
  width: 100%;
  height: 200px;
  border: 1px solid var(--dark-grey);
  padding: 10px;
  background: transparent;
}



.Contactus_main_right_infos_all {
  display: flex;
  flex-direction: column;
  text-align: initial;
  /* margin-top: 2rem; */
}

.Contactus_main_right_info {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: var(--darker-grey);
  gap: 4px;
  font-family: var(--regular);
  margin-top: 2rem;
}

.cont_icon {
  color: var(--navy);
  margin-right: 1rem;
}

.Contactus_main_right_text {
  margin-top: 3rem;
  display: flex;
  font-size: 1.1rem;
  font-family: var(--regular);
  color: var(--navy);
  text-align: initial;
}

.click {
  cursor: pointer;
  font-family: var(--bold);
  margin-left: 5px;
}
.INVALID {
  color: red;
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.VALID {
  color: var(--green);
  margin-top: -1rem;
  margin-bottom: -1rem;
}



.Contactus_main_form_button {
  margin-top: 3rem;
  margin-bottom: 2rem; 
  text-align: center;
}

.Contactus_main_form_button button {
  padding: 15px 30px;
  font-size: 1.4rem;
  font-family: 'Arial', sans-serif;
  text-decoration: none;
  background-color: #171546;
  color: #fff;
  border: 2px solid #0d0d2b;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
}

.Contactus_main_form_button button:disabled {
  background-color: #a0a0a0;
}

.Contactus_main_form_button button:hover:enabled {
  background-color: #0d0d2b;
  color: #fff;
  transform: scale(1.05);
}