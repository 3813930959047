.main-container {
    height: 100vh;
    background-color: white;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 640px) {
    .main-container {
        padding: 32px 32px;
    }
}

@media (min-width: 1024px) {
    .main-container {
        padding-left: 32px;
        padding-right: 32px;
    }
}

.text-center {
    text-align: center;
}

.error-code {
    font-size: 1rem;
    font-weight: 600;
    color: #4f46e5; /* Indigo 600 */
}

.page-title {
    margin-top: 1rem;
    font-size: 3rem; /* Adjust for responsiveness */
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: -0.02em;
    color: #111827; /* Gray 900 */
}

@media (min-width: 640px) {
    .page-title {
        font-size: 4.5rem; /* Larger title for larger screens */
    }
}

.error-message {
    margin-top: 1.5rem;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.75rem; /* Adjusted for balance */
    color: #6b7280; /* Gray 500 */
}

@media (min-width: 640px) {
    .error-message {
        font-size: 1.25rem;
        line-height: 2rem;
    }
}

.action-buttons {
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
}

.home-button {
    display: inline-block;
    border-radius: 0.375rem;
    background-color: #4f46e5; /* Indigo 600 */
    padding: 0.625rem 1.5rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: white !important;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    text-align: center;
    text-decoration: none;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.home-button:hover {
    background-color: #4338ca; /* Indigo 500 */
}

.home-button:focus-visible {
    outline: 2px solid #4f46e5;
    outline-offset: 2px;
}

.support-link {
    font-size: 0.875rem;
    font-weight: 600;
    color: #111827; /* Gray 900 */
    text-decoration: none;
}

.support-link span {
    margin-left: 0.25rem;
}
