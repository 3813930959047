@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap");

:root {
  --clr-head: hsl(246, 35%, 11%);
  --clr-body: hsl(248, 35%, 11%);
}

.Main_qna {
  height: 400vh;
  background-color: var(--grey);
  font-family: var(--regular);
}

.qna_cont {
  font-family: "Mulish", sans-serif;
  width: 80%;
  margin: auto;
  padding: 20px;
  padding-top: 20rem;
  font-size: 1.6rem;
  color: var(--clr-body);
  display: flex;
  align-items: center;
  justify-content: center;
}

.app {
  max-width: 66em;
  width: 80%;
  background-color: white;
  border-radius: 10px;
  position: relative;
  padding: 3em;
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  gap: 2rem;
}

.app::before {
  content: "";
  position: absolute;
  top: -8rem;
  left: -8rem;
  width: 206px;
  height: 206px;
  background-color: hsl(340, 57%, 45%);
  z-index: -1;
}

.app::after {
  content: "";
  position: absolute;
  bottom: -8rem;
  right: -8rem;
  width: 206px;
  height: 206px;
  background: transparent;
  border-right: 3.5rem solid hsl(340, 57%, 45%);
  border-bottom: 3.5rem solid hsl(340, 57%, 45%);
  z-index: -1;
}

.app__head {
  margin-top: 2em;
}

.app__head h1 {
  font-size: clamp(3rem, 5vw, 3.6rem);
  font-weight: 700;
  width: 80%;
  line-height: 1.2;
}

.accordion {
  margin-bottom: 0.5em;
  max-width: 588px;
  border-radius: 3px;
  width: 100%;
  border: 1px solid hsla(0, 0%, 90%, 1);
  cursor: pointer;
}

.accordion.is-open {
  background-color: hsl(220, 8%, 95%);
}

.accordion__header button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: none;
  background-color: #fff;
  padding: 2em;
  text-align: left;
  outline: none;
}

.accordion__title {
  font-size: 1.8rem;
  font-weight: 400;
}

.accordion__icon {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.icon__plus {
  transform: rotate(0deg);
}

.icon__minus {
  transform: rotate(180deg);
}

.accordion.is-open .icon__plus {
  transform: rotate(-90deg);
}

.accordion__content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.accordion.is-open .accordion__content {
  max-height: 500px; /* Set a maximum height for the content */
}

.accordion__inner {
  padding: 1em 1.5em;
}

.accordion__inner p {
  font-size: 1.6rem;
  line-height: 1.4;
  margin-bottom: 1em;
  color: var(--clr-body);
  opacity: 0.7;
}

@media (max-width: 768px) {
  .app {
    max-width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
  }
  .app__head h1 {
    width: 90%;
  }

  .app::before {
    display: none;
  }

  .app::after {
    display: none;
  }
}
