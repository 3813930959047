.Main_profile_cont {
  height: auto;
  padding-bottom: 5rem;
}

/* .red_icon {
    z-index: 4;
    position: relative;
    display: flex;
    top: 180px;
    left: 100px;


} */
.profile_cont {
  padding-top: 11rem;
}

.profile_img_cont {
  position: absolute;
}

.profile_img_pro {
  border-radius: 8px;
  padding: 5px;
}

.red_icon {
  position: relative;
  z-index: 2;
  display: flex;
  margin-left: 5rem;
  width: 70%;
}

.edit_cont {
  position: relative;
  z-index: 4;
  bottom: 340px;
  left: 280px;
  width: fit-content;
}

.edit {
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.profile_info {
  padding-top: 24rem;
}

.profile_info_cont {
  display: flex;
  flex-direction: column;
  margin-left: 5rem;
  float: left;
  margin-left: 10%;
  font-size: var(--medium);
}

.profile_info_cont_text {
  font-family: var(--medium);
  color: var(--navy);
  font-size: var(--medium);
  text-align: left;
  font-size: 24px;
}

.profile_info_cont_text1 {
  font-weight: bold;
  color: var(--navy);
  font-size: 40px;
  text-align: left;
}

.Doc_text {
  font-size: 24px;
  font-family: var(--medium);
  font-weight: bold;
  color: var(--navy);
  text-align: left;
  margin-left: 10%;
  margin-top: 15%;
  bottom: 20%;
}

.JOB_TEXT,
.Applied_jobs_text {
  font-size: 24px;
  font-family: var(--medium);
  font-weight: bold;
  color: var(--navy);
  text-align: left;
  margin-left: 10%;
  margin-top: 5%;
  bottom: 20%;
}

.TEXT1 {
  position: absolute;
  top: 850px;
  color: white;
  font-size: 40px;
  font-family: var(--medium);
  font-weight: bold;
  margin-left: 25%;
  z-index: 2;
}

.TEXT2 {
  position: absolute;
  top: 810px;
  color: white;
  font-size: 40px;
  font-family: var(--medium);
  font-weight: bold;
  margin-left: 41%;
  z-index: 2;
  width: 250px;
}

.TEXT3 {
  position: absolute;
  top: 810px;
  color: white;
  font-size: 40px;
  font-family: var(--medium);
  font-weight: bold;
  margin-left: 62%;
  z-index: 2;
  width: 250px;
}

.PROFILE_BACKGROUND {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 3%;
  margin-left: 1.25%;
  margin-bottom: -12px;
}

.bg1,
.bg2,
.bg3 {
  width: 250px;
  height: 150px;
  border-top-right-radius: 10%;
  border-top-left-radius: 10%;
  z-index: 1;
  margin-right: 100px;
}

/* .bg1 {
  margin-right: 2%;
}

.bg2 {
  margin-left: 5px;
}

.bg3 {
  margin-left: 2.5%;
} */

.cover_cont {
  display: flex;
}

.cover {
  width: 180px;
  margin-left: 2rem;
  bottom: 300px;
  position: relative;
  z-index: 2;
  height: 14rem;
}
.cover1 {
  width: 20rem;

  margin-left: 1rem;
  bottom: 300px;
  position: relative;
  z-index: 2;
  height: 20rem;
  margin-top: 0rem;
  border-radius: 26rem;
}

.jobs_info {
  display: flex;
  flex-direction: column;
  font-size: var(--medium);
  gap: 15px;

  margin-left: 10%;
  margin-top: 1rem;
  width: 80%;
  height: 110%;
  padding: 10px;
}

.view_button {
  color: var(--navy);
  font-size: var(--medium);
  font-family: var(--medium);
  font-weight: bold;
  border: 1px solid var(--navy);
  border-radius: 5px;
  padding: 10px;
  width: 100px;
  cursor: pointer;
  background-color: white;
  margin-top: 1rem;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.Applied_Jobs_cont {
  display: flex;
  flex-direction: column;
  font-size: var(--medium);
  gap: 10px;
  margin-top: 2rem;
}

.view_button1 {
  color: var(--navy);
  font-size: var(--medium);
  font-family: var(--medium);
  font-weight: bold;
  border: 1px solid var(--navy);
  border-radius: 5px;
  padding: 10px;
  width: 100px;
  cursor: pointer;
  background-color: white;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.more {
  color: var(--navy);
  font-family: var(--medium);
  font-weight: bold;
  align-items: center;
}

.CURRICULUM_VITEA {
  background-image: url("./../../assets/images/profile/CV_cover.png");
  background-color: var(--navy);
  opacity: 0.5;
  color: white;
  font-size: 50px;
  font-family: var(--medium);
  font-weight: bold;
  width: auto;
  height: 200px;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.COVER_LETTER,
.CERTIFICATES {
  background-color: var(--green);
  color: white;
  font-size: 50px;
  font-family: var(--medium);
  font-weight: bold;
  width: auto;
  height: 200px;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CERTIFICATES {
  background-color: var(--purple);
}

.b1 {
  color: #ff0000;
  border: 1px solid red;
}

.title_text {
  font-size: 3rem;
  color: var(--navy);
  font-family: var(--bold);
}

.b2 {
  color: var(--green);
  border: 1px solid var(--green);
}

.b3 {
  color: var(--purple);
  border: 1px solid var(--purple);
}

@media screen and (max-width: 960px) {
  .Applied_Jobs_cont {
    margin-left: 4rem;
    width: 300px;
    margin: 10%;
    height: auto;
  }

  .more {
    margin-left: -4rem;
  }
}

@media screen and (max-width: 860px) {
  .Doc_text {
    font-size: 20px;
    margin-top: 30%;
  }
}
@media screen and (max-width: 500px) {
  .red_icon {
    margin-left: 0px;
  }
  .cover {
    margin-right: 2rem;
    margin-left: 0px;
  }
}

@media screen and (max-width: 400px) {
  .heading,
  .Main_profile_cont {
    width: 100%;
  }
}
