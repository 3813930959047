.card_cont {
    width: fit-content;
    height: fit-content;

}

.Main_div {
    width: 16rem;
    height: 100%;
}

.card_img {
    width: 16rem;
    height: 200px;
    opacity: 0;
    cursor: pointer;
    transition: .5s ease;

}

.card_img_1 {
    width: 16rem;
    height: 200px;
    opacity: 1;
    cursor: pointer;
    transition: .5s ease;

}

.card_img_cont {
    position: absolute;
    z-index: 1;
}

.card_img:hover {
    opacity: 1;
}

.card_cont:hover {
    box-shadow: 0px 0px 20px 6px #0000003d;

}

.card_text_cont {
    position: relative;
    color: white;
    width: 16rem;
    height: 200px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.card_text_cont:hover {
    background: linear-gradient(40deg, rgba(0, 0, 0, 0.7) 21.21%, rgba(0, 0, 0, 0) 81.85%);
}




.card_title {
    font-size: 1.4rem;

    text-align: center;
    font-family: var(--bold);
    margin-bottom: 10px;
    color: var(--navy);
}

.card_title_1 {
    font-size: 1.8rem;
    text-align: center;
    font-family: var(--bold);
    margin-bottom: 10px;
    color: white;
}

.card_desc_1 {
    font-size: 1.1rem;
    text-align: center;
    font-family: var(--regular);
    color: white;
}

.card_desc {
    font-size: 1.1rem;
    text-align: center;
    font-family: var(--regular);
    color: var(--pink);

}