.applied_list {
  height: 100%;
  background-color: var(--grey);
  padding-bottom: 5rem;
}

.applied_list_container {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 20rem;
}
