.Main_auth {
  height: auto;
  background-color: var(--grey);
  padding-bottom: 20px;
}

.auth_cont {
  padding-top: 20rem;
}

.home_section_auth {
  width: 45%;
  background: #a2bf83 0% 0% no-repeat padding-box;
  border-radius: 12px;
  height: 500px;
}

.home_section4_button_text_5 {
  background-color: white;
  color: var(--navy);
  border-radius: 5px;
  width: 150px;
  padding: 5px;
  text-align: center;
  font-family: var(--bold);
  cursor: pointer;
}
.home_section4_button_text_5:hover {
  background-color: var(--navy);
  color: white;
  border-radius: 5px;
  width: 150px;
  padding: 5px;
  text-align: center;
  font-family: var(--bold);
  cursor: pointer;
}

.home_section_auth_2 {
  width: 45%;
  background: var(--yellow) 0% 0% no-repeat padding-box;
  border-radius: 12px;
  height: 500px;
}


@media screen and (max-width: 790px){
  .home_section_auth{
    width: 100%;
  }
  .home_section_auth_2{
    width:100%;
  }
}