.login_form_cont {
  padding-top: 20rem;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
}
.eye {
  display: flex;
  align-items: center;
}

.login-cont {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 60%;
}

@media screen and (max-width: 768px){
  .login-cont {
    width: 100%;
  }
}

.error {
  color: red;
  font-size: 1.2rem;
  font-family: var(--bold);
}
.forget_pasword {
  color: #1877f2;
  font-size: 14px;
  font-weight: 500;
}
.forget_pasword:hover {
  text-decoration: underline;
  cursor: pointer;
}
