.SavedJobsCard_container {
  display: flex;
  flex-direction: row;
  background-color: white;
  justify-content: space-between;
  height: 250px;
  padding: 15px;
  cursor: pointer;
}
.SavedJobsCard_container:hover {
  box-shadow: -1px 5px 29px 11px rgba(0, 0, 0, 0.17);
  -webkit-box-shadow: -1px 5px 29px 11px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: -1px 5px 29px 11px rgba(0, 0, 0, 0.17);
}
.Libanlogo {
  width: 200px;
  height: 200px;
  margin-left: 1rem;
}

.MARK_EXPERIENCE {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: start !important;
}

.info_card_cont {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
}

.MARKETING_CONT1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 550px;
  color: var(--dark-grey);
  height: 20%;
}

.MARKETING_CONT2 {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 250px;
  cursor: pointer;
}

.MARK_TITLE {
  font-size: 24px;
  color: var(--navy);
  font-weight: bold;
  font-family: var(--medium);
}

.MARK_NAME {
  color: red;
}

.Desc {
  color: var(--green);
  text-align: right;
}

.CONT2_BUTTON {
  width: auto;
  font-size: 16px;
  font-weight: var(--medium);
  font-family: var(--medium);
  color: var(--navy);
  border: 2px solid var(--navy);
  border-radius: 5px;
  background-color: white;
  margin-left: 55%;
  padding: 0.5rem;
}

.BOOKMARK_ICON {
  width: 20px;
  height: auto;
  margin-top: 1rem;
  color: var(--navy);
}

.POSTDATE {
  color: var(--navy);
  font-size: 14px;
  font-family: var(--medium);
  margin-bottom: 1rem;
}

@media screen and (max-width: 1320px) {
  .SavedJobsCard_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    justify-content: space-between;
    height: auto;
  }

  .MARKETING_CONT1 {
    width: 250px;
  }

  .Libanlogo,
  .CONT2_BUTTON {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 960px) {
  .SavedJobsCard_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
  }

  .Desc {
    align-items: center;
    margin-left: -2rem;
  }

  .BOOKMARK_ICON {
    margin-left: 12rem;
  }

  .CONT2_BUTTON {
    margin-left: -1rem;
    margin-top: 2rem;
  }

  .Libanlogo {
    margin-left: 1rem;
  }
}
