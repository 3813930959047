.Main_header_div {
  display: flex;
  transition: all 0.5s;
  box-shadow: 0px 14px 16px -10px rgba(0, 0, 0, 0.15) !important;
  z-index: 10000;
  position: fixed;
  background-color: white;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100px;
  background-color: #ffff;
  border: 2px solid #f5f5f5;
  align-items: center;
}

.Header_logo_container {
  width: 20%;
}

.Header_nav_container {
  width: 55%;
}

.Header_login_container {
  width: 15%;
}

.Header_container {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  gap:13px;
  /* justify-content: space-between; */
  cursor: pointer;
}

.Header_nav_container {
  display: flex;
  flex-direction: row;
  /* gap: 4rem; */
  justify-content: space-around;
}

.Header_nav_item {
  font-family: var(--bold);
  font-size: 1.2rem;
  color: var(--navy);
  white-space: nowrap;
}

.Header_nav_item_highlighted {
  font-family: var(--bold);
  font-size: 1.2rem;
  color: var(--navy);
  background: var(--yellow);
}

.Header_nav_item.active:after {
  content: "";

  background-color: var(--yellow);
  transition: all 0.5s;
}

.Header_nav_item:hover {
  background-color: var(--yellow);
}
.subsection_text:hover {
  background-color: var(--yellow);
}
.Header_nav_item.active {
  background-color: var(--yellow);
  color: var(--navy);
}

.Header_login_container {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
}

.Header_logedin_profile {
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  position: relative;
}

.Header_logedin_profile_image_icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: fill;
  cursor: pointer;
}

.Header_login_button {
  cursor: pointer;
  background-color: var(--navy);
  color: white;
  padding: 7px;
  border-radius: 8px;
  font-family: var(--medium);
  display: flex;
  align-items: center;
}

.Header_nav_dropdown {
  z-index: 10005;
  top: 40px;
  position: absolute;
  background-color: white;
  box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.5) !important;
  width: fit-content;
  white-space: nowrap;
  padding: 10px 10px;
  left: -50px;
  border-radius: 5px;
}

.Header_nav_dropdown_2 {
  z-index: 10005;
  top: 40px;
  position: absolute;
  background-color: white;
  box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.5) !important;
  width: fit-content;
  white-space: nowrap;
  padding: 10px 10px;
  left: -5px;
  border-radius: 5px;
}

.Header_search_input {
  width: auto;
  border: none;
  outline: none;
  font-family: var(--regular);
  font-size: 1rem;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #f5f5f5;
  color: var(--navy);
  transition: all 0.5s;
}

.Header_search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1.3rem;
}

.Header_nav_dropdown_profile {
  z-index: 10005;
  top: 65px;
  position: absolute;
  background-color: white;
  box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.5) !important;
  width: fit-content;
  white-space: nowrap;
  padding: 10px 10px;
  left: -60px;
  border-radius: 5px;
}

.Header_nav_item_wrapper {
  position: relative;
}

.Header_nav_dropdown_cont {
  display: flex;
  flex-direction: column;
}

.subsection_text {
  font-family: var(--bold);
  font-size: 1.1rem;
  color: var(--navy);
  padding: 5px 10px;
  cursor: pointer;
  transition: all 0.5s;
}

.Header_burger_menu {
  display: none;
}

@media screen and (max-width: 1350px) {
  .Main_header_div {
    padding: 0 0;
  }

  .Header_login_container {
    width: 10%;
  }
}

@media screen and (max-width: 1257px) {
  .Header_nav_container {
    width: 60%;
  }

  .Header_container {
    width: 100%;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1257px) {
  .Header_login_container {
    display: none;
  }

  .Header_nav_container {
    display: none;
  }

  .Header_burger_menu {
    display: block;
    width: 20%;
    cursor: pointer;
  }
}
