/* Main Job Listing Container */
.Main_job_listing {
  height: auto;
  background-color: var(--light-grey);
  padding-bottom: 5rem;
}

/* Job Listing Body */
.joblisting_body {
  margin: auto;
  margin-top: 15rem;
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Heading Style */
.Aboutus_heading_text_title_2 {
  font-size: 3.5rem;
  font-family: var(--bold);
  text-align: center;
  margin-top: 3rem;
  color: var(--navy);
}

/* Filter Bar */
.filter_bar {
  margin-top: 3rem;
  width: 100%;
  background-color: var(--light-grey);
  padding: 1.5rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Jobs Container */
.jobs_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 3rem;
}

/* Trash Container */
.trash_cont {
  background-color: var(--grey);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.trash_cont:hover {
  background-color: var(--dark-grey);
}

/* Upper Filters Box */
.upperfilters_box {
  margin-top: 2rem;
}

/* Search Input */
.input_complete_search {
  width: 85%;
  padding: 1.5rem;
  border: 2px solid var(--dark-grey);
  border-radius: 10px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.input_complete_search:focus {
  border-color: var(--navy);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

/* Filter Icons Container */
.filter_icons_container {
  background-color: white;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Dropdown Title */
.title_drop_cont {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 1rem;
  background-color: var(--light-grey);
  border-radius: 8px;
  transition: background-color 0.3s;
}

.title_drop_cont:hover {
  background-color: var(--dark-grey);
}

.arrow_icon {
  transform: rotate(270deg);
  transition: transform 0.5s;
}

.arrow_icon_rot {
  transform: rotate(90deg);
}

.icons_dropdown {
  position: relative;
}

.title_drop {
  font-size: 1.2rem;
  font-family: var(--bold);
  color: var(--navy);
}

/* Dropdown Menu Styles */
.Header_nav_dropdown_job, .Header_nav_dropdown_job_2 {
  z-index: 10005;
  position: absolute;
  top: 60px;
  background-color: white;
  box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.3);
  width: fit-content;
  padding: 1rem;
  border-radius: 10px;
  max-height: 300px;
  overflow-y: auto;
}

.subsection_text_jobs {
  font-family: var(--bold);
  font-size: 1.1rem;
  color: var(--navy);
  padding: 0.3rem 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap:5px;
}

.subsection_text_jobs:hover {
  background-color: var(--navy);
  color: white;
}

/* Checkbox Filter */
.checkbox_filter {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

/* Icons Dropdown */
.icons_drop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.5rem;
  align-items: center;
  flex-wrap: nowrap;
}

/* Search Container */
.cont_search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  margin: auto;
  border-radius: 8px;
  background-color: white;
}

/* Description */
.Desc {
  color: var(--green);
  text-align: right;
  height: auto;
  white-space: normal;
  transition: max-height 0.3s ease, overflow 0.3s ease;
  padding: 1rem;
  background-color: var(--light-grey);
  border-radius: 8px;
}

/* Button View */
.buttonview {
  margin-top: 2rem;
  padding: 1rem 2rem;
  background-color: var(--navy);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.buttonview:hover {
  background-color: var(--dark-grey);
}

/* Responsive Styles */
@media screen and (max-width: 1320px) {
  .joblisting_body {
    width: 90%;
  }

  .icons_drop {
    flex-wrap: wrap;
    gap: 1rem;
  }
}

@media screen and (max-width: 1186px) {
  .Desc {
    max-height: none;
    overflow: visible;
  }
}

@media screen and (max-width: 576px) {
  .joblisting_body {
    margin-top: 10rem;
  }

  .Desc {
    max-height: none;
    overflow: visible;
  }

  .input_complete_search {
    width: 95%;
    padding: 1rem;
  }
}
