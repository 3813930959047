.Main_success_page {
  height: auto;
  background-color: var(--grey);
  padding-bottom: 5rem;
}

.success_stories {
  padding-top: 20rem;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.success_stories_heading {
  font-size: 3rem;
  font-family: var(--bold);
}

.success_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 4rem;
  gap: 2rem;
  justify-content: center;
}
